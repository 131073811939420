<template>
    <div>
        <!--<v-row>
            <v-col cols="12" md="12">
                <v-card class="mx-auto fill-height" min-height="280" outlined>
                    <v-card-title>
                        Preisdaten Direkt-Abfrage
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="productname" label="Produktname" outlined dense></v-text-field> <v-btn
                            @click="searchPrice">Suchen</v-btn>
                        <v-container fluid>
                            <v-textarea v-model="productoutput" filled label="Label" auto-grow></v-textarea>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-divider></v-divider>-->
        <h2>Strom</h2>
        <pricetableStrom></pricetableStrom>
        <h2>Gas</h2>
        <pricetableGas></pricetableGas>
        <!--<v-row>
        <pre>{{
          JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
</template>

<script>
import pricetableStrom from "../components/pricetable_strom.vue"
import pricetableGas from "../components/pricetable_gas.vue"

export default {
    components: {
        pricetableStrom,
        pricetableGas
    },
    data() {
        return {}
          
    },
    methods: {
      
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
      
    }
}
</script>

<style></style>