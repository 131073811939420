var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", [
      _vm._m(0),
      _c(
        "tbody",
        [
          _vm._l(_vm.groupedData, function (items, year) {
            return [
              _vm._l(items, function (item, index) {
                return [
                  _c("tr", { key: item.query.productname }, [
                    index === 0
                      ? _c(
                          "td",
                          {
                            class: {
                              highlight:
                                _vm.highlightChanges[item.query.productname] &&
                                _vm.highlightChanges[item.query.productname]
                                  .year,
                            },
                            attrs: { rowspan: items.length },
                          },
                          [_vm._v(" " + _vm._s(year) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        class: {
                          highlight:
                            _vm.highlightChanges[item.query.productname] &&
                            _vm.highlightChanges[item.query.productname]
                              .productname,
                        },
                      },
                      [_vm._v(" " + _vm._s(item.query.productname) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          highlight:
                            _vm.highlightChanges[item.query.productname] &&
                            _vm.highlightChanges[item.query.productname]
                              .timestamp,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatTimestamp(item.debug.age.product_ts)
                            )
                        ),
                        _c("br"),
                        _c("small", [
                          _vm._v(
                            "(" +
                              _vm._s(
                                _vm.formatRelativeTime(item.debug.age.priceAge)
                              ) +
                              ")"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          highlight:
                            _vm.highlightChanges[item.query.productname] &&
                            _vm.highlightChanges[item.query.productname].price,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.formatPrice(item.price)) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        class: {
                          highlight:
                            _vm.highlightChanges[item.query.productname] &&
                            _vm.highlightChanges[item.query.productname]
                              .priceRaw,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatPrice(
                                item.debug.gesammelteAufschlaege.priceRaw
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatNumber(
                              item.debug.gesammelteAufschlaege
                                .normaufschlaegeWert +
                                item.debug.gesammelteAufschlaege
                                  .zeitaufschlaegeWert
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", [
                      _c("div", [
                        _c("strong", [_vm._v("GESAMT: ")]),
                        _vm._v(
                          _vm._s(
                            _vm.formatNumber(
                              item.debug.gesammelteAufschlaege
                                .normaufschlaegeWert
                            )
                          )
                        ),
                      ]),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.filteredNormaufschlaege(item),
                          function (normaufschlag, key) {
                            return _c(
                              "li",
                              {
                                key: key,
                                class: {
                                  highlight:
                                    _vm.highlightChanges[
                                      item.query.productname
                                    ] &&
                                    _vm.highlightChanges[item.query.productname]
                                      .normaufschlaege &&
                                    _vm.highlightChanges[item.query.productname]
                                      .normaufschlaege[key],
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(key) +
                                    ": " +
                                    _vm._s(
                                      _vm.germanNumberStringToNumber(
                                        normaufschlag.wert
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("td", [
                      _c("div", [
                        _c("strong", [_vm._v("GESAMT: ")]),
                        _vm._v(
                          _vm._s(
                            _vm.formatNumber(
                              item.debug.gesammelteAufschlaege
                                .zeitaufschlaegeWert
                            )
                          )
                        ),
                      ]),
                      _c(
                        "ul",
                        _vm._l(
                          item.debug.gesammelteAufschlaege
                            .angewendeteZeitaufschlaege,
                          function (value, key) {
                            return _c(
                              "li",
                              {
                                key: key,
                                class: {
                                  highlight:
                                    _vm.highlightChanges[
                                      item.query.productname
                                    ] &&
                                    _vm.highlightChanges[item.query.productname]
                                      .zeitaufschlaege &&
                                    _vm.highlightChanges[item.query.productname]
                                      .zeitaufschlaege[key],
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(value.beschreibung) +
                                    ": " +
                                    _vm._s(
                                      _vm.germanNumberStringToNumber(value.wert)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Jahr")]),
        _c("th", [_vm._v("Produkt")]),
        _c("th", [_vm._v("Preisstand")]),
        _c("th", [_vm._v("Preis")]),
        _c("th", [_vm._v("DFC Preis")]),
        _c("th", [_vm._v("Aufschlaege")]),
        _c("th", [_vm._v("Normaufschlaege")]),
        _c("th", [_vm._v("Zeitaufschlaege")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }