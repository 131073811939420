var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Strom")]),
      _c("pricetableStrom"),
      _c("h2", [_vm._v("Gas")]),
      _c("pricetableGas"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }