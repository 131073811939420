<template>
    <div>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto fill-height" min-height="280" outlined>
            <v-card-title>
              Preisdaten
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="$store.state.produkte.allProductsArray"
              :items-per-page="15"
              :search="search"
              class="elevation-1"
            >
              <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{  value }">
                  {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!--<v-row>
        <pre>{{
          JSON.stringify($store.state.contract.aktuellerVertrag, null, 2)
        }}</pre>
      </v-row>-->
    </div>
  </template>
  
<script>
  
export default {
    components: {
    },
    data() {
        return {
            search: "",
            headers: [
                {
                    text: "Produkt",
                    align: "start",
                    value: "id"
                },
                { text: "Anbieter", value: "anbieter" },
                { text: "Preis", value: "price", formatter: this.germanNumberFormat },
                { text: "Datum", value: "timestamp", formatter: this.getDateFromTS }
            ]
        }
    },
    methods: {
        getDateFromTS(ts) {
            const timestamp = parseInt(ts)
            const dateobj = new Date(timestamp)
            return dateobj.toLocaleString("de-DE")
        },
        germanNumberFormat(number) {
            if (typeof(number) === "string") {
                number=parseFloat(number)
            }
            return number.toLocaleString("de-DE")
        }
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store
            .dispatch("getFrontProducts" )
            .then(() => {
                console.log("fertisch")
        
            })
    }
}
</script>
  
  <style>
  </style>