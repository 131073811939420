<template>
    <div>
        <table>
            <thead>
                <tr>
                    <th>Jahr</th>
                    <th>Produkt</th>
                    <th>Preisstand</th>

                    <th>Preis</th>
                    <th>DFC Preis</th>
                    <th>Aufschlaege</th>
                    <th>Normaufschlaege</th>
                    <th>Zeitaufschlaege</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(items, year) in groupedData" >
                    <template v-for="(item, index) in items">
                        <tr :key="item.query.productname">
                            <td v-if="index === 0" :rowspan="items.length"
                                :class="{ 'highlight': highlightChanges[item.query.productname] && highlightChanges[item.query.productname].year }">
                                {{ year }}
                            </td>
                            <td
                                :class="{ 'highlight': highlightChanges[item.query.productname] && highlightChanges[item.query.productname].productname }">
                                {{ item.query.productname }}
                            </td>
                            <td
                                :class="{ 'highlight': highlightChanges[item.query.productname] && highlightChanges[item.query.productname].timestamp }">
                                {{ formatTimestamp(item.debug.age.product_ts) }}<br>
                                <small>({{ formatRelativeTime(item.debug.age.priceAge) }})</small>
                            </td>
                            
                            <td
                                :class="{ 'highlight': highlightChanges[item.query.productname] && highlightChanges[item.query.productname].price }">
                                {{ formatPrice(item.price) }}
                            </td>
                            
                            <td
                                :class="{ 'highlight': highlightChanges[item.query.productname] && highlightChanges[item.query.productname].priceRaw }">
                                {{ formatPrice(item.debug.gesammelteAufschlaege.priceRaw) }}
                            </td>
                            <td>
                                {{ formatNumber(item.debug.gesammelteAufschlaege.normaufschlaegeWert + item.debug.gesammelteAufschlaege.zeitaufschlaegeWert) }}
                            </td>
                            <td>
                                <div><strong>GESAMT: </strong>{{ formatNumber(item.debug.gesammelteAufschlaege.normaufschlaegeWert) }}</div>
                                <ul>
                                    <li v-for="(normaufschlag, key) in filteredNormaufschlaege(item)"
                                        :key="key"
                                        :class="{ 'highlight': highlightChanges[item.query.productname] && 
                                            highlightChanges[item.query.productname].normaufschlaege && 
                                            highlightChanges[item.query.productname].normaufschlaege[key] }">
                                        {{ key }}: {{ germanNumberStringToNumber(normaufschlag.wert) }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <div><strong>GESAMT: </strong>{{ formatNumber(item.debug.gesammelteAufschlaege.zeitaufschlaegeWert) }}</div>
                                <ul>
                                    <li v-for="(value, key) in item.debug.gesammelteAufschlaege.angewendeteZeitaufschlaege" :key="key"
                                        :class="{ 'highlight': highlightChanges[item.query.productname] && 
                                            highlightChanges[item.query.productname].zeitaufschlaege && 
                                            highlightChanges[item.query.productname].zeitaufschlaege[key] }">
                                        {{ value.beschreibung }}: {{ germanNumberStringToNumber(value.wert) }}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

export default {
    data() {
        return {
            data: [],
            highlightChanges: {},
            isLoading: false,
        };
    },
    computed: {
        groupedData() {
            const grouped = {};

            this.sortedData.forEach(item => {
                const year = this.extractYear(item.query.productname);
                if (!grouped[year]) {
                    grouped[year] = [];
                }
                grouped[year].push(item);
            });

            return grouped;
        },
        sortedData() {
            return [...this.data].sort((a, b) => this.extractYear(a.query.productname) - this.extractYear(b.query.productname));
        },
        filteredNormaufschlaege() {
            return item => {
                const filtered = {};
                for (const key in item.debug.gesammelteAufschlaege.normaufschlaege) {
                    if (key !== "Standard") {
                        filtered[key] = item.debug.gesammelteAufschlaege.normaufschlaege[key];
                    }
                }
                return filtered;
            };
        }
    },
    methods: {
        formatRelativeTime(seconds) {
            const date = new Date();
            const timeAgo = formatDistanceToNow(date.setTime(date.getTime() - seconds * 1000), { addSuffix: true, locale: de });
            return timeAgo;
        },
        extractYear(productname) {
            const match = productname.match(/\d{4}$/);
            return match ? parseInt(match[0]) : null;
        },
        extractPriceType(productname) {
            const match = productname.match(/\d{4}$/);
            return match ? parseInt(match[0]) : null;
        },
        async fetchData() {
            const currentDate = new Date()
            const currentYear = currentDate.getFullYear()
            // Berechne die Jahre für die Abfrage (das aktuelle Jahr und die nÃ¤chsten 3 Jahre)
            const years = []
            const lines = ["B"]
            const potentialDocumentNames=[]
            for (let i = 1; i <= 2; i++) {
                years.push(currentYear + i)
            }

            years.forEach(year => {
                lines.forEach(line => {
                    potentialDocumentNames.push(`G0${line}Y01${year}`)
                })
            })


            this.isLoading = true;
            const requests = [];
            const products = potentialDocumentNames;
            const debug = true;

            products.forEach(product => {
                const data = new FormData();
                data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY);
                data.append("product", product);
                if (debug) {
                    data.append("debug", "true");
                }

                const config = {
                    method: "post",
                    url: `${process.env.VUE_APP_FIREBASE_HOSTING}/queryPrice`,
                    data: data
                };

                const request = axios(config)
                    .then(response => {
                        this.updateData(response.data);
                    })
                    .catch(error => {
                        console.error(`Error fetching data for product ${product}:`, error);
                    });

                requests.push(request);
            });

            await Promise.all(requests);
            this.isLoading = false;
        },
        updateData(responseData) {
            const index = this.data.findIndex(item => item.query.productname === responseData.query.productname);
            if (index !== -1) {
                const existingData = this.data[index];
                const highlightChanges = {};

                if (existingData.query.timestamp !== responseData.query.timestamp) {
                    highlightChanges.timestamp = true;
                }
                if (existingData.price !== responseData.price) {
                    highlightChanges.price = true;
                }
                if (existingData.debug.age.priceAge !== responseData.debug.age.priceAge) {
                    highlightChanges.priceAge = true;
                }
                if (existingData.debug.priceRaw !== responseData.debug.priceRaw) {
                    highlightChanges.priceRaw = true;
                }

                highlightChanges.normaufschlaege = {};
                for (const key in responseData.debug.gesammelteAufschlaege.normaufschlaege) {
                    if (existingData.debug.gesammelteAufschlaege.normaufschlaege[key].wert !== responseData.debug.gesammelteAufschlaege.normaufschlaege[key].wert) {
                        highlightChanges.normaufschlaege[key] = true;
                    }
                }

                highlightChanges.zeitaufschlaege = {};
                for (const key in responseData.debug.gesammelteAufschlaege.zeitaufschlaege) {
                    if (existingData.debug.gesammelteAufschlaege.zeitaufschlaege[key].wert !== responseData.debug.gesammelteAufschlaege.zeitaufschlaege[key].wert) {
                        highlightChanges.zeitaufschlaege[key] = true;
                    }
                }

                this.$set(this.highlightChanges, responseData.query.productname, highlightChanges);
                this.$set(this.data, index, responseData);

                setTimeout(() => {
                    this.$delete(this.highlightChanges, responseData.query.productname);
                }, 1500);
            } else {
                this.data.push(responseData);
            }
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            return date.toLocaleString("de-DE");
        },
        formatPrice(price) {
            return Number(price).toLocaleString("de-DE", { style: "currency", currency: "EUR" });
        },
        formatNumber(number) {
            return Number(number).toLocaleString("de-DE");
        },
        germanNumberStringToNumber(string) {
            string = string.replace(".", "")
            string = string.replace(",", ".")
            return parseFloat(string).toLocaleString("de-DE");
        }
    },
    mounted() {
        this.fetchData();
        setInterval(this.fetchData, 60000 + Math.floor(Math.random() * 10000 + 1));
    }
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    width: 12.5%; /* Ensure each column takes an equal width */
}

ul {
    list-style-type: none;
    padding: 0;
}

.highlight {
    background-color: yellow;
    transition: background-color 0.5s ease;
}
</style>
