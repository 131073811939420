import { render, staticRenderFns } from "./pricetable_gas.vue?vue&type=template&id=564aee4f&scoped=true&"
import script from "./pricetable_gas.vue?vue&type=script&lang=js&"
export * from "./pricetable_gas.vue?vue&type=script&lang=js&"
import style0 from "./pricetable_gas.vue?vue&type=style&index=0&id=564aee4f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564aee4f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('564aee4f')) {
      api.createRecord('564aee4f', component.options)
    } else {
      api.reload('564aee4f', component.options)
    }
    module.hot.accept("./pricetable_gas.vue?vue&type=template&id=564aee4f&scoped=true&", function () {
      api.rerender('564aee4f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pricetable_gas.vue"
export default component.exports