var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto fill-height",
                  attrs: { "min-height": "280", outlined: "" },
                },
                [
                  _c(
                    "v-card-title",
                    [_vm._v(" Preisdaten "), _c("v-spacer")],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.$store.state.produkte.allProductsArray,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(
                          _vm.headers.filter((header) =>
                            header.hasOwnProperty("formatter")
                          ),
                          function (header) {
                            return {
                              key: `item.${header.value}`,
                              fn: function ({ value }) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(header.formatter(value)) + " "
                                  ),
                                ]
                              },
                            }
                          }
                        ),
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }